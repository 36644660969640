import { FormControl } from '@angular/forms';

/**
 * Class representing the Validation helper.
 */
export class ValidationHelper {

	static prepareValue(value: any): any {
		if (typeof value === 'string') {
			return value
			.trim()
			.replace(/\s+/g, ' ');
		}
		return value;
	}

	/**
	 * Validates an email address (with domain extension).
	 * @param {FormControl} c - Angular form FormControl object
	 * @return {object} Angular form FormControl validation object
	 */
	static validateEmail(c: FormControl): { [key: string]: any } {
		const emailRegexp = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
		return emailRegexp.test(c.value) ? null : {
			validateEmail: {
				valid: false
			}
		};
	}

	/**
	 * Validates an email address (with domain extension).
	 * @param {FormControl} c - Angular form FormControl object
	 * @return {object} Angular form FormControl validation object
	 */
	static validateNautaConnectEmail(c: FormControl): { [key: string]: any } {
		const emailRegexp = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@nautaconnect\.com$/i;
		return emailRegexp.test(c.value) ? null : {
			validateNautaConnectEmail: {
				valid: false
			}
		};
	}

	/**
	 * Validates if a trimmed value is not falsy.
	 * @param {FormControl} control - Angular form control
	 * @return {ValidationInterface} Angular validation object
	 */
	static requiredValidator(control: FormControl) {
		const isValid: boolean = !!ValidationHelper.prepareValue(control.value);

		if (isValid) { return null; }

		return {
			isRequired: true,
		};
	}
}
