import { NgModule, ApplicationRef } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// Added scrolling module
import { ScrollingModule } from '@angular/cdk/scrolling';

import { removeNgStyles, createNewHosts } from '@angularclass/hmr';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Custom modules
import { SharedModule } from '../modules/shared/shared.module';
import { LoginModule } from '../modules/login/login.module';
import { HelpModule } from '../modules/help/help.module';
import { OnboardingModule } from '../modules/onboarding/onboarding.module';
import { ReservationModule } from '../modules/reservation/reservation.module';
import { ManagementModule } from '../modules/management/management.module';

// Routes
import { AppRouting, appRoutingProviders } from '../routes/app.routes';
import { AuthGuard } from '../routes/auth-guard';
import { AdHocAuthGuard } from '../routes/ad-hoc-auth-guard';
import { ConditionsAuthGuard } from '../routes/conditions-auth-guard';
import { CabinetAuthGuard } from '../routes/cabinet-auth-guard';

// Container components
import { AppComponent } from '../components/app.component';

// Reducers, store and state
import { rootReducers, metaReducers } from '../store/root.reducer';
import { InitialState } from '../store/initial-state';

// Services
import { LocaleService } from '../services';

// SCSS
import '../../scss/main.scss';

// Creates the API base url for use throughout the application
export const _API_BASE_URL_ = (
	window.location.hostname === 'localhost'
		? window.location.protocol + '//localhost:3000/api/'
		: (
			window.location.hostname === 'app-demo1-locker.azurewebsites.net'
				? window.location.protocol + '//api-demo1-locker.azurewebsites.net/api/'
				: (
					window.location.hostname === 'pilot.cloud-locker.com'
						? window.location.protocol + '//api-demo1-locker.azurewebsites.net/api/'
						: window.location.protocol + '//api-demo1-locker.azurewebsites.net/api/'
				)
		)
);

// Creates the recipe images base url for use throughout the applications
export const _IMG_BASE_URL_ = (
	process.env.ENV === 'build' ? '/images/products/' : process.env.HOST + 'images/products/'
);

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		HttpModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		// Added scrolling module
		ScrollingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/i18n/', '.json'),
				deps: [HttpClient]
			}
		}),
		AppRouting,
		StoreModule.forRoot(
			rootReducers, {
				initialState: InitialState,
				metaReducers: metaReducers
			}
		),
		StoreDevtoolsModule.instrument({
			maxAge: 5
		}),
		SharedModule,
		HelpModule,
		OnboardingModule,
		ReservationModule,
		ManagementModule,
		LoginModule,
	],
	declarations: [
		AppComponent,
		// LazyForDirective
	],
	providers: [
		{ provide: APP_BASE_HREF, useValue: '/' },
		[
			{ provide: 'ApiEndpoint', useValue: _API_BASE_URL_ },
			{ provide: 'ImagesUrl', useValue: _IMG_BASE_URL_ }
		],
		appRoutingProviders,
		AuthGuard,
		AdHocAuthGuard,
		ConditionsAuthGuard,
		CabinetAuthGuard,
		LocaleService
	],
	bootstrap: [AppComponent]
})

export class AppModule {
	constructor(public appRef: ApplicationRef) { }

	hmrOnInit(store) {
		console.log('HMR store', store);
	}

	hmrOnDestroy(store) {
		let cmpLocation = this.appRef.components
			.map(cmp => cmp.location.nativeElement);

		// Recreates elements
		store.disposeOldHosts = createNewHosts(cmpLocation);

		// Removes styles
		removeNgStyles();
	}

	hmrAfterDestroy(store) {

		// Displays new elements
		store.disposeOldHosts();
		delete store.disposeOldHosts;
	}
}
