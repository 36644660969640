import { Recipe } from '../../store/recipes/recipes.models';
import { Cabinet } from '../../store/cabinets/cabinets.models';
import { CabinetPosition } from '../../store/cabinet-positions/cabinet-positions.models';
import { CustomNotification } from '../../store/customnotifications/customnotifications.models';
import { ApplicationSetting } from '../../store/applicationsettings/applicationsettings.models';
import { DateTimeHelper } from '../date-time/date-time.helper';

interface ApiItem {
	id: number;
	DescrNL: string;
	DescrEN: string;
}

interface ParsedItem {
	id: number;
	name: {
		nl: string;
		en: string;
	};
}

interface ApiRecipeItem {
	Active: boolean;
	active?: boolean;
	Availability: Array<{
		endDateTime: number;
		startDateTime: number;
		nextRegistrationStartDT: number;
	}> | null;
	Barcode1: string | null;
	Barcode2: string | null;
	Barcode3: string | null;
	Barcode4: string | null;
	Barcode5: string | null;
	BarcodeDescrEN1: string | null;
	BarcodeDescrEN2: string | null;
	BarcodeDescrEN3: string | null;
	BarcodeDescrEN4: string | null;
	BarcodeDescrEN5: string | null;
	BarcodeDescrNL1: string | null;
	BarcodeDescrNL2: string | null;
	BarcodeDescrNL3: string | null;
	BarcodeDescrNL4: string | null;
	BarcodeDescrNL5: string | null;
	CategoryID: number;
	DefectCounter: number | null;
	DescrEN: string;
	DescrNL: string;
	DescriptionEN: string;
	DescriptionNL: string;
	FailedLendingCntr: number | null;
	ID: number;
	Image: string;
	ImmediateReturn: boolean;
	LendingCntr: number | null;
	LocationID: number;
	MaxLendingHours: number;
	CreateDT: string;
	UpdateDT: string;
	AvailableForTeacher: boolean;
	AvailableForStudent: boolean;
	Defect?: boolean;
	Overdue?: boolean;
	Lent?: boolean;
	cabinetPosition: string | null;
	cabinetPositionType: number;
}

interface ApiRecipeRecipeItems { // @TODO implement this
	Barcode: string | null;
	BarcodeDescEN: string | null;
	BarcodeDescNL: string | null;
	ID: string;
	image: string | null;
}

interface ApiCabinetItem {
	Active: boolean;
	CreateDT: string | null;
	DeleteDT: string | null;
	Descr: string;
	ID: number;
	IsDeleted: boolean;
	LocationID: number;
	UpdateDT: string | null;
	DescriptionEN: string;
	DescriptionNL: string;
	fromEmail: string | null;
	adminEmail: string | null;
	ICTEmail: string | null;
	reportEmail: string | null;
	ShowInManagement: number | null;
}

interface ApiCabinetPositionItem {
	BladeNo: number;
	BladePosNo: number;
	CabinetDoorID: number; // new
	CabinetID: number;
	CreateDT: string | null; // new
	DeleteDT: string | null; // new
	ID: number;
	ImmediateReturn: boolean; // new
	IsDeleted: boolean; // new
	IsDirty: boolean; // new
	LastSyncDT: string | null; // new
	LockedByCabinetProfileID: number; // new
	PictureURL: string | null; // new (huh?)
	PositionDescr: string; // new (huh?)
	PositionNo: number;
	PositionType: number; // new, was PositionTypeID
	RecipeID: number;
	Status: number;
	SummaryDescrEN: string | null;
	SummaryDescrNL: string | null;
	UpdateDT: string | null;
}

interface ApiCustomNotificationItem {
	ID: number;
	Name: string | null;
	TranslationNL: string | null;
	TranslationEN: string | null;
}

interface ApiApplicationSettingItem {
	ID: number;
	Name: string | null;
	DescrNL: string | null;
	DescrEN: string | null;
	Value: string | null;
}

/**
 * Class representing the Items helper.
 */
export class ItemsHelper {

	/**
	 * Map an items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items
	 * @return {array} - Parsed items
	 */
	static parseItems(items: Array<ApiItem>): Array<ParsedItem> {
		return items.map(item => ({
			id: item.id,
			name: {
				nl: item.DescrNL,
				en: item.DescrEN
			}
		}));
	}

	/**
	 * Map a recipes items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items - Recipes items
	 * @return {array} - Parsed items
	 */
	static mapRecipeItems(items: Array<ApiRecipeItem>): any { // Array<Recipe> {
		const getLabels: Function = recipe => {
			let labels: string[] = recipe.labels || [];
			if (recipe.Defect) { labels.push('defect'); }
			if (recipe.Overdue) { labels.push('overdue'); }
			if (recipe.Lent) { labels.push('lent'); }
			return labels;
		};

		return items.map(item => ({
			id: item.ID,
			name: {
				nl: item.DescrNL,
				en: item.DescrEN
			},
			description: {
				nl: item.DescriptionNL,
				en: item.DescriptionEN
			},
			imgName: item.Image,
			categoryId: item.CategoryID,
			locationId: item.LocationID,
			isImmediateReturn: item.ImmediateReturn,
			maxDuration: (item.MaxLendingHours * 60),
			availability: !item.Availability ? [] : item.Availability.map(slot => {
				const { endDateTime, startDateTime, nextRegistrationStartDT } = slot;

				const getMinutes = timeStamp => DateTimeHelper.getMinutes(
					DateTimeHelper.getTimeByUnixTimeStamp(timeStamp)
				);

				return {
					date: DateTimeHelper.getDateByUnixTimeStamp(startDateTime, 'YYYY-MM-DD'),
					start: getMinutes(startDateTime),
					end: getMinutes(endDateTime),
					nextRegistrationStartDT
				};
			}),
			items: {
				barcode1: item.Barcode1,
				description1: {
					nl: item.BarcodeDescrNL1,
					en: item.BarcodeDescrNL1
				},
				barcode2: item.Barcode2,
				description2: {
					nl: item.BarcodeDescrNL2,
					en: item.BarcodeDescrNL2
				},
				barcode3: item.Barcode3,
				description3: {
					nl: item.BarcodeDescrNL3,
					en: item.BarcodeDescrNL3
				},
				barcode4: item.Barcode4,
				description4: {
					nl: item.BarcodeDescrNL4,
					en: item.BarcodeDescrNL4
				},
				barcode5: item.Barcode5,
				description5: {
					nl: item.BarcodeDescrNL5,
					en: item.BarcodeDescrNL5
				}
			},
			// Workaround for API inconsistency in naming
			isActive: item.Active !== undefined ? item.Active : item.active,
			isDefect: item.Defect || false,
			dateCreated: item.CreateDT,
			dateLastEdited: item.UpdateDT,
			isAvailableTeacher: item.AvailableForTeacher,
			isAvailableStudent: item.AvailableForStudent,
			labels: getLabels(item) || [],
			cabinetPosition: item.cabinetPosition,
			cabinetPositionType: item.cabinetPositionType
		}));
	}

	/**
	 * Map a cabinets items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items - Recipes items
	 * @return {array} - Parsed items
	 */
	static mapCabinetItems(items: Array<ApiCabinetItem>): Array<Cabinet> {
		return items.map(item => ({
			id: item.ID,
			description: item.Descr,
			locationId: item.LocationID,
			isActive: item.Active,
			descriptionEN: item.DescriptionEN !== null ? item.DescriptionEN : '',
			descriptionNL: item.DescriptionNL !== null ? item.DescriptionNL : '',
			fromEmail: item.fromEmail,
			adminEmail: item.adminEmail,
			ICTEmail: item.ICTEmail,
			reportEmail: item.reportEmail,
			showInManagement: item.ShowInManagement
		}));
	}

	/**
	 * Map a cabinet position items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items - Recipes items
	 * @return {array} - Parsed items
	 */
	static mapCabinetPositionItems(items: Array<ApiCabinetPositionItem>): Array<CabinetPosition> {
		return items.map(item => ({
			id: item.ID,
			recipeId: item.RecipeID,
			cabinetId: item.CabinetID,
			positionNumber: item.PositionNo,
			bladePositionNumber: item.BladePosNo,
			positionTypeId: item.PositionType,
			status: item.Status
		}));
	}

	/**
	 * Map an items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items
	 * @return {array} - Parsed items
	 */
	static mapCustomNotificationItems(items: Array<ApiCustomNotificationItem>): Array<CustomNotification> {
		return items.map(item => ({
			id: item.ID,
			name: item.Name,
			translationNL: item.TranslationNL,
			translationEN: item.TranslationEN
		}));
	}

	/**
	 * Map an items array as retrieved by the API. These items will be mapped to front-end interface.
	 * @param {array} items
	 * @return {array} - Parsed items
	 */
	static mapApplicationSettingItems(items: Array<ApiApplicationSettingItem>): Array<ApplicationSetting> {
		return items.map(item => ({
			id: item.ID,
			name: item.Name,
			label: {
				nl: item.DescrNL,
				en: item.DescrEN
			},
			value: item.Value
		}));
	}
}
