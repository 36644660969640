import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { StateInterface, Account, Locations, Locale } from '../../store/state.model';
import { UserMeta } from '../../store/auth/auth.models';
import { LocaleService, LayoutService, UsersService, FilterService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { _ } from '../../tools';
import { Location } from '../../store/locations/locations.models';
import { locations } from 'src/app/store/locations/locations.reducer';

@Component({
	selector: 'onboarding',
	template: require('./onboarding-view.component.html')
})

/**
 * Class representing the OnboardingViewComponent component.
 */
export class OnboardingViewComponent extends BaseComponent {

	/**
	 * @property {Account} account - The account state.
	 */
	account: Account;

	/**
	 * @property {Locale}  locale - The locale state.
	 */
	locale: Locale;

	/**
	 * @property {Array<Location>} - All available locations
	 */
	locations: Array<Location>;

	/**
	 * @property {UserMeta} userMeta - The user meta data
	 */
	userMeta: UserMeta = {
		preferedLocationId: 0,
		preferedLanguage: 'nl',
	};

	locationSelected: Number = 0;
	/**
	 * @property {string} returnUrl - The url to return to when all is accepted.
	 */
	returnUrl: string = '';

	/**
	 * Constructor.
	 * @param {LocaleService} localeService
	 * @param {LayoutService} layoutService
	 * @param {UsersService} usersService
	 * @param {FilterService} filterService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {Store} store
	 */

	constructor(
		private localeService: LocaleService,
		private layoutService: LayoutService,
		private usersService: UsersService,
		private filterService: FilterService,
		private router: Router,
		private route: ActivatedRoute,
		private store: Store<StateInterface>
	) {
		super();

		// Subscribes to the locale state
		this.addSubscription(store.pipe(select('locale'))
			.subscribe((locale: Locale) => {
				this.locale = _.cloneDeep(locale);
			})
		);

		// Subscribes to the locations state
		this.addSubscription(store.pipe(select('locations'))
			.subscribe((locations: Locations) => {
				this.locations = locations.items;
			})
		);
	}

	/**
	 * Upon initializing the component.
	 * @return {void}
	 */
	ngOnInit(): void {
		window.scrollTo(0, 0);

		// Sets page-header navigation
		this.store.dispatch(
			this.layoutService.editLayout({
				leftNav: null,
				rightNav: null
			})
		);

		// Subscribes to the account state
		this.addSubscription(this.store.pipe(select('account'))
			.subscribe((account: Account) => {
				this.account = _.cloneDeep(account);
				this.userMeta = account.meta;
			})
		);

		this.addSubscription(this.route.queryParams.subscribe(params => {
			// Defaults to 0 if no query param provided.
			this.returnUrl = params['returnUrl'] || '';
		}));
	}

	/**
	 * Change the current language.
	 * @param {string} locale
	 * @return {void}
	 */
	onChangeLanguage(locale: string): void {
		const { meta } = this.account;
		let language = 'nl-NL';

		if (locale === 'en') {
			language = 'en-GB';
		}

		this.store.dispatch(
			this.localeService.setCurrent(locale)
		);

		this.userMeta = Object.assign({}, meta, {
			'preferedLanguage': language,
		});

		this.usersService.patchUserMeta(this.userMeta).subscribe((userDataFromApi: any) => {
			this.store.dispatch(
				this.usersService.setUserMeta(userDataFromApi),
			);
		});
	}

	/**
	 * Sets and saves the location filter.
	 * @param location
	 */
	setAndSaveLocationFilter(locationId: any): void {
		console.log('Location ID onboarding:');
		console.log(locationId);
		const { meta } = this.account;

		this.userMeta = Object.assign({}, meta, {
			'preferedLocationId': locationId,
		});

		// Saves the meta data to the DB and updates the users meta in the store.
		this.usersService.patchUserMeta(this.userMeta).subscribe((userDataFromApi: any) => {
			this.store.dispatch(
				this.usersService.setUserMeta(userDataFromApi),
			);

			this.store.dispatch(
				this.filterService.resetState(),
			);
			this.store.dispatch(
				this.filterService.editFilter({ locationId: userDataFromApi.preferedLocationId }),
			);
		});
	}

	/**
	 * Logout event
	 * @param event
	 */
	logout(event: any): void {
		if (event) {
			event.preventDefault();
		}
		sessionStorage.removeItem('stateSnapshot');
		location.reload();
	}

	/**
	 * Continue to the conditions.
	 * @param {any} $event
	 */
	onContinueClick($event: any): void {
		let routeObject = {};

		if (this.returnUrl.length > 0) {
			routeObject = {
				queryParams: {
					returnUrl: this.returnUrl,
				}
			};
		}
		if (this.locationSelected === 0) {
			console.log('Get last item in array');
			this.locationSelected = this.locations[this.locations.length - 1].id;
			console.log(this.locationSelected);
		}
		this.setAndSaveLocationFilter(this.locationSelected);
		this.router.navigate(['/reservation/conditions'], routeObject);
	}
}
