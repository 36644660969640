import { ActionReducer } from '@ngrx/store';

import { Actions, ActionTypes } from './layout.actions';
import { LayoutInterface as Layout } from './layout.models';
import { tassign } from '../../tools';

/**
 * Please do not modify the store directly. Always use reducers.
 */

export const initialLayoutState: Layout = {
	leftNav: null,
	rightNav: null,
	isCategoriesExpanded: false,
	isNavigationVisible: false,
	isBottomBarVisible: true,
	bottomBarItems: [
		{
			id: 'filter',
			roles: ['superusers', 'administrators', 'students', 'employees'],
			isActive: true,
			routerLink: '/reservation/filter',
			icon: 'search-icon',
			label: 'RESERVATION.GENERAL.search-text'
		},
		{
			id: 'overview',
			roles: ['superusers', 'administrators', 'students', 'employees'],
			isActive: true,
			routerLink: '/reservation/overview',
			icon: 'menu-icon',
			label: 'RESERVATION.GENERAL.overview-text'
		},
		{
			id: 'active',
			roles: ['superusers', 'administrators', 'students', 'employees'],
			isActive: true,
			routerLink: '/reservation/active',
			icon: 'menu-icon',
			label: 'RESERVATION.GENERAL.reservations-text',
			hideOnMobile: true
		},
		{
			id: 'ict-box',
			roles: ['superusers', 'administrators', 'employees'],
			isActive: true,
			routerLink: '/reservation/ict-box',
			icon: 'compose-icon',
			label: 'RESERVATION.GENERAL.ict-box-text'
		},
		{
			id: 'drop-box',
			roles: ['superusers', 'administrators', 'employees'],
			isActive: true,
			routerLink: '/reservation/drop-box',
			icon: 'compose-icon',
			label: 'RESERVATION.GENERAL.drop-box-text'
		},
		{
			id: 'management',
			roles: ['superusers', 'administrators'],
			isActive: true,
			routerLink: '/management',
			icon: 'menu-icon',
			label: 'MANAGEMENT.GENERAL.title',
			hideOnMobile: true
		},
		{
			id: 'settings',
			roles: ['superusers', 'administrators', 'students', 'employees'],
			isActive: true,
			routerLink: '/reservation/settings',
			icon: 'settings-icon',
			label: 'RESERVATION.GENERAL.settings-text'
		},
		{
			id: 'help',
			roles: ['superusers', 'administrators', 'students', 'employees'],
			isActive: true,
			routerLink: '/help',
			icon: 'settings-icon',
			label: 'RESERVATION.GENERAL.help-text'
		},
	],
	isLoading: false
};

/**
 * Reduce the store.
 * @param {Layout} state - the current state
 * @return {Layout} The new state
 */
export const layout: ActionReducer<any, Actions> = (state: Layout = initialLayoutState, action: Actions): Layout => {
	switch (action.type) {

		case ActionTypes.EDIT_LAYOUT:
			return action.payload;

		case ActionTypes.TOGGLE_CATEGORIES:
			return tassign(state, {
				isCategoriesExpanded: action.payload
			});

		case ActionTypes.TOGGLE_NAVIGATION:
			return tassign(state, {
				isNavigationVisible: action.payload
			});

		case ActionTypes.TOGGLE_BOTTOM_BAR:
			return tassign(state, {
				isBottomBarVisible: action.payload
			});

		case ActionTypes.EDIT_BOTTOM_BAR_ITEMS:
			return tassign(state, {
				bottomBarItems: action.payload
			});

		case ActionTypes.RESET_STATE:
			return initialLayoutState;

		case ActionTypes.SET_IS_LOADING:
			return tassign(state, {
				isLoading: action.payload
			});
	}
	return state;
};
