import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
// Added scrolling module
import { ScrollingModule } from '@angular/cdk/scrolling';

// Custom modules
import { SharedModule } from '../shared/shared.module';

// Reducers, store and state
import { rootReducers, metaReducers } from '../../store/root.reducer';
import { InitialState } from '../../store/initial-state';

// Services
import { AuthenticationService, LocaleService, LocationsService, CustomNotificationsService, ApplicationSettingsService, CategoriesService, RecipesService, ManagementRecipesService, ManagementRecipeItemsService, CabinetsService, CabinetPositionsService, FilterService, RegistrationService, FeedbackService, LayoutService, UsersService, ReportsService, AuditlogService } from '../../services';

// Container components
import { ManagementViewComponent } from '../../components/management-view/management-view.component';
import { RecipesViewComponent } from '../../components/management-view/recipes-view/recipes-view.component';
import { RecipeViewComponent } from '../../components/management-view/recipes-view/recipe-view/recipe-view.component';
import { CategoriesViewComponent } from '../../components/management-view/categories-view/categories-view.component';
import { CabinetsViewComponent } from '../../components/management-view/cabinets-view/cabinets-view.component';
import { CabinetLocationViewComponent } from '../../components/management-view/cabinets-view/cabinet-view/location-view/location-view.component';
import { CabinetAdministratorsViewComponent } from '../../components/management-view/cabinets-view/cabinet-view/administrators-view/administrators-view.component';
import { CabinetAvailabilityViewComponent } from '../../components/management-view/cabinets-view/cabinet-view/availability-view/availability-view.component';
import { CabinetAuditlogViewComponent } from '../../components/management-view/cabinets-view/cabinet-view/auditlog-view/auditlog-view.component';
import { CabinetEmailSettingsViewComponent } from '../../components/management-view/cabinets-view/cabinet-view/emailsettings-view/emailsettings-view.component';
import { UsersViewComponent } from '../../components/management-view/users-view/users-view.component';
import { UserViewComponent } from '../../components/management-view/users-view/user-view/user-view.component';
import { ReportsViewComponent } from '../../components/management-view/reports-view/reports-view.component';
import { ReportUsageViewComponent } from '../../components/management-view/reports-view/report-usage-view/report-usage-view.component';
import { AdministratorsViewComponent } from '../../components/management-view/administrators-view/administrators-view.component';
import { CustomNotificationsViewComponent } from '../../components/management-view/customnotifications-view/customnotifications-view.component';
import { CustomNotificationViewComponent } from '../../components/management-view/customnotifications-view/customnotification-view/customnotification-view.component';
import { ApplicationSettingsViewComponent } from '../../components/management-view/applicationsettings-view/applicationsettings-view.component';
import { ApplicationSettingViewComponent } from '../../components/management-view/applicationsettings-view/applicationsetting-view/applicationsetting-view.component';

// Presentational components
import { NavigationComponent } from '../../components/management-view/navigation/navigation.component';
import { PageCanvasComponent } from '../../components/management-view/ui/page-canvas/page-canvas.component';
import { SearchBlockComponent } from '../../components/management-view/search-block/search-block.component';
import { ClickableListItemComponent } from '../../components/management-view/ui/clickable-list-item/clickable-list-item.component';
import { ProductListEditItemComponent } from '../../components/management-view/ui/product-list-edit-item/product-list-edit-item.component';
import { ProductListInlineEditItemComponent } from '../../components/management-view/ui/product-list-inline-edit-item/product-list-inline-edit-item.component';
import { LocationAvailabilityComponent } from '../../components/management-view/ui/location-availability/location-availability.component';
import { UserListItemComponent } from '../../components/management-view/ui/user-list-item/user-list-item.component';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		// Added scrolling module
		ScrollingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/i18n/', '.json'),
				deps: [HttpClient]}
		}),
		StoreModule.forRoot(
			rootReducers, {
				initialState: InitialState,
				metaReducers: metaReducers
			}
		),
		SharedModule
	],
	declarations: [
		ManagementViewComponent,
		RecipesViewComponent,
		RecipeViewComponent,
		CategoriesViewComponent,
		CabinetsViewComponent,
		CabinetLocationViewComponent,
		CabinetAdministratorsViewComponent,
		CabinetAvailabilityViewComponent,
		CabinetAuditlogViewComponent,
		CabinetEmailSettingsViewComponent,
		UsersViewComponent,
		UserViewComponent,
		ReportsViewComponent,
		ReportUsageViewComponent,
		AdministratorsViewComponent,
		CustomNotificationsViewComponent,
		CustomNotificationViewComponent,
		ApplicationSettingsViewComponent,
		ApplicationSettingViewComponent,
		NavigationComponent,
		PageCanvasComponent,
		SearchBlockComponent,
		ClickableListItemComponent,
		ProductListEditItemComponent,
		ProductListInlineEditItemComponent,
		LocationAvailabilityComponent,
		UserListItemComponent,
	],
	providers: [
		AuthenticationService,
		LocaleService,
		LocationsService,
		CustomNotificationsService,
		ApplicationSettingsService,
		CategoriesService,
		RecipesService,
		ManagementRecipesService,
		ManagementRecipeItemsService,
		CabinetsService,
		CabinetPositionsService,
		FilterService,
		RegistrationService,
		FeedbackService,
		LayoutService,
		UsersService,
		ReportsService,
		AuditlogService
	]
})

export class ManagementModule {}
